import React from "react";
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import Cards from "./components/Cards";
import Download from "./components/Download";
import Footer from "./components/Footer";
import Description from "./components/Description";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "reactstrap/lib";
import FaqSection from "./components/FaqSection";
import ExclusiveFeatures from "./components/ExclusiveFeatures";
import AnnouncementBar from "./components/AnnouncementBar";

 const messages = [
  "🎉 Sign up today and receive an incredible Cash Back or Welcome Bonus on your first deposit!",
  "🎉 Sign up today and receive an incredible Cash Back or Welcome Bonus on your first deposit!"
];

const App = () => {
  return (
    <React.Fragment>
      <div className="app-bg">
        <AnnouncementBar messages={messages} />
        <NavBar />
        <Banner />
        <Description />
        <ExclusiveFeatures />
        <Cards />
        <Download />
        <FaqSection />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default App;
