import React from "react";
import { Row, Col, Container } from "reactstrap";
import "../styles/Description.css";

const Description = () => {
  return (
    <React.Fragment>
      <div className="description-bg">
        <Container>
          <h2 className="what-exactly-is-des text-center mb-5">
            Why Choose a Game Vault for Your Casino Gaming?
          </h2>
          <Row className="variety-wrapper variety-wrapper-1">
            <Col xs={12} sm={12} md={6} lg={5} className="mb-4">
              <div className="feature-card">
                <i className="bi bi-globe feature-icon"></i>
                <h3 className="feature-title">24/7 Global Support</h3>
                <p className="feature-description">
                  Once you download Game Vault, you’ll have all the support you
                  need. Their support team is available 24/7 to help solve any
                  issues quickly and ensure your business thrives.
                </p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={5} className="mb-4">
              <div className="feature-card">
                <i className="bi bi-shield-lock feature-icon"></i>
                <h3 className="feature-title">Advanced Security Protocols</h3>
                <p className="feature-description">
                  The casino prioritizes the safety of your online casino and
                  its players. Their security protocols are regularly updated to
                  keep you secure. The Game Vault slots online platform uses
                  encryption and the latest safety systems to ensure smooth and
                  safe operation.
                </p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={5} className="mb-4">
              <div className="feature-card">
                <i className="bi bi-eye feature-icon"></i>
                <h3 className="feature-title">Constant Threat Monitoring</h3>
                <p className="feature-description">
                  The casino constantly monitors threats, letting you focus on
                  what truly matters—your players' experience. With Game Vault,
                  online login and gaming are smooth and secure, so you and your
                  players can simply enjoy the fun of gaming!
                </p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={5} className="mb-4">
              <div className="feature-card">
                <i className="bi bi-wallet2 feature-icon"></i>
                <h3 className="feature-title">Simple Payment System</h3>
                <p className="feature-description">
                  The Game Vault online casino software includes multiple
                  payment options, allowing players to make quick deposits and
                  cashouts. With Game Vault, players can easily manage payments
                  for a smooth gaming experience.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Description;
