import React, { useState } from "react";
import { Container } from 'reactstrap';
import "../styles/FaqSection.css";

const FaqSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "Can I Play Game Vault Without Downloading it?",
            answer: "Yes, you can play Game Vault Casino without downloading anything! Just open it directly in your web browser. If you like gaming on your phone, there’s also a Game Vault casino app available."
        },
        {
            question: "How Do I Create a Game Vault Account?",
            answer: "To set up a Game Vault account, go to the Game Vault website and download the app. Then, sign up for an account. Once you verify your details, you’ll receive login info to start playing."
        },
    ];

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <React.Fragment>
            <div className="description-bg">
                <Container>
                    <h2 className="what-exactly-is-faq">FAQs (Frequently Asked Questions)</h2>
                        {faqs.map((faq, index) => (
                            <div className="faq-container" key={index} onClick={() => toggleAnswer(index)}>
                                <div className="question">
                                    <span>{faq.question}</span>
                                    <span className={`arrow ${activeIndex === index ? "expanded" : ""}`}>{`>`}</span>
                                </div>
                                {activeIndex === index && (
                                    <div className="answer">
                                        {faq.answer}
                                    </div>
                                )}
                            </div>
                        ))}

                </Container>
            </div>
        </React.Fragment>
    );
};

export default FaqSection;
