import React from "react";
import { Row, Col, Container } from "reactstrap";
import "../styles/ExclusiveFeatures.css"; // Ensure this is updated for styles

const ExclusiveFeatures = () => {
  return (
    <div className="description-bg">
      <Container>
        <h2 className="what-exactly-is-exclusive text-center mb-5">
          Get Exclusive Features with the Game Vault App
        </h2>
        <Row className="variety-wrapper variety-wrapper-1">
          <Col xs={12} sm={12} md={6} lg={3} className="content-box mb-4">
            <div className="feature-card">
              <i className="bi bi-gift feature-icon"></i> {/* Icon */}
              <h3 className="feature-title">Free to Play</h3>
              <p className="feature-description">
                Game Vault Slots lets you enjoy card games, slot machines, and
                more without spending a single penny.
              </p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} className="content-box mb-4">
            <div className="feature-card">
              <i className="bi bi-controller feature-icon"></i>
              <h3 className="feature-title">Wide Range of Games</h3>
              <p className="feature-description">
                The casino app offers a huge selection of games with different
                genres, so players can find a variety of titles that suit their
                interests.
              </p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} className="content-box mb-4">
            <div className="feature-card">
              <i className="bi bi-chat-square-text feature-icon"></i>
              <h3 className="feature-title">Reviews and Recommendations</h3>
              <p className="feature-description">
                Users can rate and review games, helping others learn more
                before playing.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="variety-wrapper variety-wrapper-1">
          <Col xs={12} sm={12} md={6} lg={3} className="content-box mb-4">
            <div className="feature-card">
              <i className="bi bi-stars feature-icon"></i>
              <h3 className="feature-title">Reward System</h3>
              <p className="feature-description">
                Earn daily gifts, participate in special events, and get
                rewards for referring friends.
              </p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} className="content-box mb-4">
            <div className="feature-card">
              <i className="bi bi-trophy feature-icon"></i>
              <h3 className="feature-title">VIP Program</h3>
              <p className="feature-description">
                Enhance your gaming experience by joining the VIP program, which
                offers extra rewards and bonuses.
              </p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} className="content-box mb-4">
            <div className="feature-card">
              <i className="bi bi-bell feature-icon"></i>
              <h3 className="feature-title">Updates</h3>
              <p className="feature-description">
                Stay up-to-date with the latest news, new releases, upcoming
                events, and important announcements.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExclusiveFeatures;
