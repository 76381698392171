import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import "../styles/Download.css";

const Download = () => {
  return (
    <React.Fragment>
      <Container>
        <h2 className="download-app">
          How to Download Game Vault App & Play Casino Games Online?
        </h2>
        <div className="follow-these">
          Follow these steps to download and install the app on your device
        </div>
      </Container>

      <Container>
        <Row className="g-4">
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card className="h-100 download-card-override">
              <div className="download-img-wrapper">
                <img
                  alt="google-play"
                  className=""
                  loading="lazy"
                  src="../assets/google-play.svg"
                />
                <img
                  alt="google-play"
                  className="qr-image"
                  loading="lazy"
                  src="../assets/gameVoult-QR.png"
                  width={260}
                  height={260}
                />
              </div>
              <div className="download-for">Download for Android</div>
              <ol className="click-on-the-container">
                <li>
                  Scan the QR code with your camera or click on the link to
                  download the app.{" "}
                </li>
                <li>Press "Download"</li>
                <li>
                  Follow the instructions provided to complete the installation.
                </li>
                <li>Approve the download.</li>
                Approve the file size download.{" "}
                <li>
                Allow installation from unknown sources from your phone settings.
                </li>
                <li>Open the downloaded file using your browser. </li>
                <li>Click "Install"</li>
                <li>Open the app. </li>
                <li>Allow or deny the permissions asked. </li>
                <li>Log in.</li>
                <li>Start Playing.</li>
              </ol>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6}>
            <Card className="h-100 download-card-override">
              <div className="download-img-wrapper">
                <img
                  alt="app-store"
                  className=""
                  loading="lazy"
                  src="../assets/app-store.svg"
                />
                <img
                  alt="google-play"
                  className="qr-image"
                  loading="lazy"
                  src="../assets/gameVoult-QR.png"
                  width={260}
                  height={260}
                />
              </div>
              <div className="download-for">Download for iOS</div>
              <ol className="click-on-the-container">
                <li>Click on the link.</li>
                <li>Press "Download"</li>
                <li>
                Follow the on-screen instructions. 
                </li>
                <li>Finish installation.</li>
                <li>
                Enter the download code you get from the account holder. 
                </li>
                <li>Start Playing.</li>
              </ol>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </React.Fragment>
  );
};

export default Download;
